// extracted by mini-css-extract-plugin
export var bio = "employee-bio-module--bio--hkI4c";
export var content = "employee-bio-module--content--CSFG5";
export var currentCompany = "employee-bio-module--currentCompany--H6EkQ";
export var employee = "employee-bio-module--employee--bfFvH";
export var header = "employee-bio-module--header--LzqML";
export var headshot = "employee-bio-module--headshot--81a+s";
export var jobTitle = "employee-bio-module--job-title--SZLu1";
export var linkContainer = "employee-bio-module--link-container--EL0JW";
export var name = "employee-bio-module--name--Qo-lP";
export var pronouns = "employee-bio-module--pronouns--ytFyS";
export var socialLinks = "employee-bio-module--social-links--qmXTB";
export var socialLinksHeader = "employee-bio-module--social-links-header--rc27m";
export var teamLink = "employee-bio-module--team-link--hwRQq";